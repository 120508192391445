<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-8 d-flex align-items-stretch ">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title" v-if="form.id == ''">{{ $t('add-post-process-data') }}</h4>
                                    <h4 class="card-title" v-else>{{ $t('update-post-data-question') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <!-- <div class="row">
                                            <div class="col-md-6">
                                                <b-form-group :label="$t('category*')" label-for="Category">
                                                    <b-form-select :options="categories"
                                                                   v-model="$v.form.category_id.$model"
                                                                   :state="$v.form.category_id.$dirty ? !$v.form.category_id.$error : null"
                                                                   aria-describedby="input-2-live-feedback"
                                                                   @change="checkCategory($event)">
                                                    ></b-form-select>
                                                    <b-form-invalid-feedback id="input-2-live-feedback">This is a
                                                        required
                                                        field.
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-6">
                                                <b-form-group :label="$t('order*')" label-for="Order">
                                                    <b-form-select :options="orders"
                                                                   v-model="form.order"
                                                                   aria-describedby="input-4-live-feedback"
                                                                    @change="checkOrder($event)">
                                                    </b-form-select>
                                                <div id="input-4-live-feedback" v-if="orderError" class="custom-invalid-feedback">
                                                    {{ $t("Order already exist for current category") }}
                                                </div>
                                                </b-form-group>
                                            </div>
                                        </div> -->
                                    <div class="row" v-if="!showSubQuestions">
                                        <div class="col-md-12">
                                            <b-form-group :label="$t('question*')" label-for="question">
                                                <b-form-input type="text" id="question" name="question" autocomplete="new-password" :placeholder="$t('Question?')" v-model="$v.form.question.$model" :state="$v.form.question.$dirty ? !$v.form.question.$error : null" aria-describedby="input-4-live-feedback">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.question.required" id="input-4-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group :label="$t('response')" label-for="response">
                                                <!-- :state="$v.form.response.$dirty ? !$v.form.response.$error : null"
                                                            aria-describedby="input-4-live-feedback" -->
                                                <b-form-input type="text" id="response" name="response" autocomplete="new-password" :placeholder="$t('Enter response or leave empty')" v-model="form.response">
                                                </b-form-input>
                                                <!-- <b-form-invalid-feedback v-if="!$v.form.response.required"
                                                                             id="input-4-live-feedback">This is a required
                                                        field.
                                                    </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row" v-if="showSubQuestions">
                                        <div class="col-md-12">
                                            <b-form-group :label="$t('question*')" label-for="question">
                                                <b-form-input type="text" id="question" name="question" autocomplete="new-password" :placeholder="$t('Question?')" v-model="$v.form.question.$model" :state="$v.form.question.$dirty ? !$v.form.question.$error : null" aria-describedby="input-4-live-feedback">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.question.required" id="input-4-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <b-form-group :label="$t('response*')" label-for="response">
                                                <!-- :state="$v.form.response.$dirty ? !$v.form.response.$error : null" -->
                                                <b-form-select v-model="form.response" :options="options" @change="onChangeAnswer"></b-form-select>
                                                <!-- <b-form-invalid-feedback v-if="!$v.form.response.required"
                                                                             id="input-4-live-feedback">This is a required
                                                        field.
                                                    </b-form-invalid-feedback> -->
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div v-if="showSubQuestions && (form.response === 'yes')">
                                        <div class="row" v-for="(subQuestion, index) in form.subQuestions" :key="index">
                                            <div class="col-md-5">
                                                <b-form-group :label="$t('question*')" label-for="response">
                                                    <b-form-input type="text" id="response" name="response" autocomplete="new-password" :placeholder="$t('Enter Possible Responses separted by commas')" v-model="subQuestion.question" aria-describedby="input-4-live-feedback">
                                                    </b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-5">
                                                <b-form-group :label="$t('response')" label-for="response">
                                                    <b-form-input type="text" id="response" name="response" autocomplete="new-password" :placeholder="$t('Enter Possible Responses separted by commas')" v-model="subQuestion.answer" aria-describedby="input-4-live-feedback">
                                                    </b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-2">
                                                <b-button type="button" v-if="index === 0" variant="success" class="mr-2 btn-sm mt-4" @click="addQuestion">
                                                    {{ $t('add-new-btn')}}
                                                </b-button>
                                                <b-button type="button" v-if="index !== 0" variant="danger" class="mr-2 btn-sm mt-4" @click="deletePpdSubQuestion(subQuestion)">
                                                    {{ $t('remove-btn')}}
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                    <b-button type="submit" variant="success" :disabled="isDisabled" class="mr-2">
                                        <span v-if="form.id == ''">{{ $t('submit-btn') }}</span>
                                        <span v-else>{{ $t('update-btn') }}</span>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 align-items-stretch grid-margin">
                    <div class="card">
                        <div class="card-body new-card-body">
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref" for="inline-form-custom-select-pref">{{ $t('response-require') }}</label>
                                <toggle-button class="switch-button switchColor" v-model="form.required" :sync="true" />
                            </div>
                            <div class="col-md-12 card-toggles">
                                    <label class="select-pref"
                                           for="inline-form-custom-select-pref">{{ $t('show-question') }}</label>
                                    <toggle-button class="switch-button switchColor" v-model="form.show"
                                                   :sync="true"/>
                                </div>
                                <!-- <div class="col-md-12 card-toggles">
                                    <label class="select-pref"
                                           for="inline-form-custom-select-pref">{{ $t('spotter-question') }}</label>
                                    <toggle-button class="switch-button switchColor" v-model="form.spotter_question"
                                                   :sync="true"/>
                                </div>
                                <div class="col-md-12 card-toggles">
                                    <label class="select-pref" for="inline-form-custom-select-pref">{{ $t('chart-answer') }}</label>
                                    <toggle-button class="switch-button switchColor" v-model="form.chart_answer"
                                                   :sync="true"/>
                                </div>
                                <div class="col-md-12 card-toggles">
                                    <label class="select-pref" for="inline-form-custom-select-pref">{{ $t('chart-on-spotter') }}</label>
                                    <toggle-button class="switch-button switchColor" v-model="form.show_on_spotter"
                                                   :sync="true"/>
                                </div> -->
    
                            <div class="col-md-12 card-toggles">
                                <label class="select-pref" for="inline-form-custom-select-pref">{{ $t('add-yes-no-logic') }}</label>
                                <toggle-button class="switch-button switchColor" v-model="form.logic" @change="onChangeEventHandler" :sync="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex question-order">
                            <h4 class="card-title">{{ $t('post-data-questions') }}</h4>
                            <!-- <div>
                                    <button class="btn btn-primary" @click="ShowExportModal">Export</button>
                                    <button class="btn btn-primary ml-3" @click="ShowModal">{{ $t('edit-question-order-btn') }}</button>
                                </div> -->
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" @on-search="searchFn" :search-options="{enabled: true,searchFn: searchFn}" :totalRows="totalRecords" 
                            :isLoading.sync="isLoading" :pagination-options="{
                                    enabled: true,
                                    dropdownAllowAll: false,
                                    perPageDropdown: [10, 20, 50, 100, 200],
                                }" :rows="rows" :columns="columns">
                            <template slot="table-row" slot-scope="props">
                                    <span
                                            v-if="props.column.field === 'action'"
                                            class="text-nowrap"
                                    >
                                        <b-button size="sm" @click="editPpdQuestion(props.row.id)"
                                                class="mr-1 btn-info text-white">
                                    {{ $t('edit-btn') }}
                                </b-button>
                                <b-button size="sm" @click="deletePpdQuestion(props.row.id)"
                                            class="mr-1 btn-danger text-white">
                                    {{ $t('delete-btn') }}
                                </b-button>
                                    </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
        <!-- <b-modal id="spot-detail" :title="$t('edit-questions-order')" size="md" :hide-footer="true">
            <div class="row">
                <div class="col-md-12">
                    <b-form-group :label="$t('category*')" label-for="Category">
                        <b-form-select :options="categories"
                            v-model="$v.form.category_id.$model"
                            :state="$v.form.category_id.$dirty ? !$v.form.category_id.$error : null"
                            aria-describedby="input-2-live-feedback"
                            @change="BulkEdit()">
                        </b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-8">
                    <h5>{{ $t('questions') }}</h5>
                </div>
                <div class="col-md-3">
                    <h5>{{ $t('order') }}</h5>
                </div>
            </div>
            <div class="row mt-3" v-for="row in rowsForBulkEdit" :key="row.id">
                <div class="col-md-8 align-items-center">
                    <div>
                        <p>{{row.question}}</p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div>
                        <b-form-input type="number" class="custom-order-input" min=1  oninput="this.value = Math.abs(this.value)" required v-model.number="row.order"></b-form-input>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row m-1 float-right">
                <b-button  type="button" variant="success" class="btn btn-primary btn-sm d-flex" @click="matchingOrder">{{ $t('save-changes') }}</b-button>
            </div>
        </b-modal> -->

        <!-- export modal -->
        <!-- <b-modal id="export-modal" :title="$t('export-template')" size="md" :hide-footer="true">
            <div class="row">
                <div class="col-md-12">
                    <b-form-group :label="$t('category*')" label-for="Category">
                        <b-form-select :options="categories"
                            v-model="$v.form.category_id.$model"
                            :state="$v.form.category_id.$dirty ? !$v.form.category_id.$error : null"
                            aria-describedby="input-2-live-feedback"
                            @change="BulkEdit()">
                        </b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="modal-body-scroll">
                <div class="row mt-3">
                    <div class="col-md-8">
                        <h5>{{ $t('questions') }}</h5>
                    </div>
                </div>
                <div class="row" v-for="(row, index) in rowsForBulkEdit" :key="row.id">
                    <div class="col-md-8 align-items-center">
                        <div>
                            <p>{{ index+1 }}.  {{row.question}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row m-1 float-right">
                <b-button  type="button" variant="success" class="btn btn-primary btn-sm d-flex" @click="ExportTemplate">{{ $t('export-template') }}</b-button>
            </div>
        </b-modal> -->
    </section>
    
</template>

<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {
        SnotifyPosition
    } from 'vue-snotify'
    import {
        validationMixin
    } from "vuelidate";
    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs
    } from "vuelidate/lib/validators";
    
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
    
            return {
                form: {
                    category_id: '',
                    order: Number,
                    question: "",
                    response: "",
                    logic: false,
                    required: true,
                    chart_answer: false,
                    show_on_spotter: false,
                    spotter_question: true,
                    show: true,
                    id: "",
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    subQuestions: [{
                        question: '',
                        answer: '',
                    }]
                },
                orderForm: {
                    category_id: '',
                    order: Number,
                    question: ''
                },
                orderValue: '',
                orderError: false,
                showSubQuestions: false,
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [{
                        field: 'id', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }],
    
                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                options: {
                    'yes': 'Yes',
                    'no': 'No',
                    'CBD': 'CBD'
                },
                columns: [
                    // {
                    //     label: 'Order',
                    //     field: 'order',
                    //     filterable: true,
                    // },
                    // {
                    //     label: 'Category',
                    //     field: 'category',
                    //     filterable: true,
                    //     formatFn: this.formatCategory
                    // },
                    {
                        label: 'Question',
                        field: 'question',
                        filterable: true
                    },
                    // {
                    //     label: 'Responses',
                    //     field: 'responses',
                    //     filterable: true,
                    //     formatFn: this.formatResponses
                    // },
                    {
                        label: 'Responses Required',
                        field: 'required',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                                { value: '2', text: 'CBD' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Show Question',
                        field: 'show',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    // {
                    //     label: 'Spotter Question',
                    //     field: 'spotter_question',
                    //     sortable: true,
                    //     formatFn: this.formatFn
                    // },
                    // {
                    //     label: 'Chart Answer',
                    //     field: 'chart_answer',
                    //     sortable: true,
                    //     formatFn: this.formatFn
                    // },
                    // {
                    //     label: 'Chart on Spotter',
                    //     field: 'show_on_spotter',
                    //     sortable: true,
                    //     formatFn: this.formatFn
                    // },
                    {
                        label: 'Logic Question',
                        field: 'logic',
                        sortable: true,
                        formatFn: this.formatFn,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                rowsForBulkEdit: [],
                totalRecords: 0,
                // category_id: '',
                categories: [],
                orders: [],
                isDisabled: false,
            };
        },
        validations: {
            form: {
                id: {},
                question: {
                    required,
                },
                // response: {
                //     required,
                // },
                // category_id: {
                //     required,
                // },
            },
            orderForm: {
                // category_id: {
                //     required
                // },
                // order: {
                //     required
                // }
            }
        },
        methods: {
            // matchingOrder() {
            //     const getKey = (array,key) => array.map(a => a[key]);
            //     var findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
            //     const question_ids = (getKey( this.rowsForBulkEdit,'id'));
            //     const order_ids = (getKey( this.rowsForBulkEdit,'order'));
            //     var duplicateOrder = findDuplicates(order_ids);
            //     if(duplicateOrder != '') {
            //         this.$snotify.error('Question Order '+ duplicateOrder.toString() + ' Already exist.')
            //     } else {
            //         const data = {
            //             client_id: JSON.parse(localStorage.getItem('client_id')),
            //             question_ids : question_ids,
            //             order_ids : order_ids,
            //             category_id: this.form.category_id,
            //         }
            //         API.UpdateOrders(
            //             data,
            //             data => {
            //                 this.$root.$emit("bv::hide::modal", "spot-detail");
            //                 this.loadItems();
            //                 this.$snotify.success('Questions Order has been updated');
            //             },
            //             err => {}
            //         )
            //     }
            // },
    
            ShowModal() {
                this.BulkEdit();
                this.$root.$emit("bv::show::modal", "spot-detail");
            },
            // ShowExportModal(){
            //     // this.BulkEdit();
            //     this.$root.$emit("bv::show::modal", "export-modal");
            // },
            ExportTemplate() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    category_id: this.form.category_id,
                }
                API.exportQuestions(
                    data,
                    data => {
                        const href = URL.createObjectURL(data);
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'file.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.$snotify.success('downloaded successfully');
                    },
                    err => {}
                )
            },
            // checkCategory(event){
            //     this.checkOrder(this.form.order);
            // },
            // checkOrder(event){
            //    const data ={
            //        client_id: JSON.parse(localStorage.getItem('client_id')),
            //        orderNo : event,
            //        category_id : this.form.category_id,
            //        } 
            //       API.checkOrder(
            //         data,
            //         data => {
            //             if(data.data){
            //                 if(this.orderValue != event){
            //                     this.orderError = true;
            //                 }else{
            //                     this.orderError = false;
            //                 }
            //             }else{
            //                 this.orderError = false;
            //             }
            //         },
            //         err => {
            //         }
            //     )
    
            // },
            getCategories() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getCategories(
                    data,
                    data => {
                        this.categories = data.data;
                        // this.form.role_name = 'Admin';
                        // this.form.category_id = 'Please Select an Order'
                        let one = 1;
                        for (var i in this.categories) {
                            if (one == 1) {
                                ++one;
                                this.form.category_id = i;
                                this.$v.form.$reset();
                            }
                        }
                        this.$v.form.$reset();
                    },
                    err => {}
                )
            },
            deletePpdQuestion(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        API.deletePpdQuestion(
                            e,
                            data => {
                                this.loadItems();
                                this.$snotify.success(data.message);
                                this.form.id = '';
                                this.form.logic = false;
                                this.form.question = '';
                                this.form.response = '';
                                this.$v.form.$reset();
                                this.showSubQuestions = false;
                            },
                            err => {})
                    }
                })
            },
            editPpdQuestion(e) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                this.$v.form.$reset();
                API.editPpdQuestion(
                    e, data => {
                        // this.form.category_id = data.data.category_id;
                        // this.form.order = data.data.order;
                        this.form.question = data.data.question;
                        this.form.required = (data.data.required == 1) ? true : false;
                        this.form.show = (data.data.show == 1) ? true : false;
                        // this.form.spotter_question = (data.data.spotter_question == 1) ? true : false;
                        this.form.logic = (data.data.logic == 1) ? true : false;
                        // this.form.show_on_spotter = (data.data.show_on_spotter == 1) ? true : false;
                        // this.form.chart_answer = (data.data.chart_answer == 1) ? true : false;
    
                        this.form.id = data.data.id;
                        this.form.response = data.data.question;
                        // this.orderValue = data.data.order;
                        this.orderError = false;
                        // this.form.response = data.data.question;
    
                        let text1 = [];
                        if (data.data.responses.length > 0) {
                            data.data.responses.forEach((values, index) => {
                                text1.push(values.name);
                            });
                            this.form.response = text1.toString();
                        } else {
                            this.form.response = '';
                        }
    
                        if (this.form.logic) {
                            this.showSubQuestions = true;
                            if (data.data.logic_answer_type === 'Yes') {
                                this.form.response = 'yes';
    
                                this.form.subQuestions = [];
                                data.data.sub_questions.forEach((values, index) => {
                                    let sub_q_ans = [];
                                    let sub_q_ans_str = '';
                                    if (values.sub_questions_response.length > 0) {
                                        values.sub_questions_response.forEach((values, index) => {
                                            sub_q_ans.push(values.name)
                                        })
                                        sub_q_ans_str = sub_q_ans.toString();
                                    }
                                    this.form.subQuestions.push({
                                        id: values.id,
                                        question: values.question,
                                        answer: sub_q_ans_str
                                    });
                                });
    
    
                            } else if(data.data.logic_answer_type === 'No') {
                                this.form.response = 'no';
                            } else if(data.data.logic_answer_type === 'CBD') {
                                this.form.response = 'CBD';
                            }
                        } else {
                            this.showSubQuestions = false;
                        }
    
                    },
                    err => {}
                )
            },
            onSubmit() {
                // this.checkOrder(this.form.order);
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    // this.isDisabled = true;
                    return;
                }
                // if (this.orderError) {
                //     this.orderError = true;
                //     this.isDisabled = false;
                //     return false;
                // } else {
                //     this.isDisabled = false;
                // }
                if (this.form.question != '') {
                    this.isDisabled = true;
                    API.addEditPPDQuestion(
                        this.form,
                        data => {
                            if(data.status == 500){
                                this.isDisabled = false;
                                this.$snotify.error(data.message);
                                this.editPpdQuestion(data.id);
                            }
                            else{
                                this.isDisabled = false;
                            this.$v.form.$reset();
                            this.$snotify.success(data.message, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            }
                            this.form.category_id = '';
                            this.form.question = "";
                            this.form.response = "";
                            this.form.required = true;
                            this.form.logic = false;
                            this.form.show_on_spotter = false;
                            this.form.chart_answer = false;
                            this.form.required = true;
                            this.form.show = true;
                            this.form.spotter_question = true;
                            this.form.id = "";
                            this.getCategories();
                            this.loadItems();
                            this.orderValue = '';
                            // this.checkOrder(this.form.order);
                            this.$v.form.$reset();
                            this.showSubQuestions = false;
                        },
                        err => {
                            this.isDisabled = false;
                            this.$v.form.$reset();
                            this.$snotify.error(err, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    )
    
                } else {
                    this.isDisabled = false;
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
    
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
    
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
    
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.loadItems();
            },
    
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
    
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            BulkEdit() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    category_id: this.form.category_id,
                }
                API.getAllQuestionsOrder(data,
                    data => {
                        // this.totalRecords = data.totalRecords;
                        this.rowsForBulkEdit = data.data;
    
                    },
                    err => {}
                )
            },
            loadItems() {
                this.isLoading = true;
                API.getPPDQuestions(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        // this.form.category_id = data.data.data[0].client_id;
                        // this.users = data
                    },
                    err => {}
                )
            },
            formatFn: function(value) {
            console.log('value :', value);
                if (value == 1) {
                    return 'Yes';
                }
                else if (value == 0) {
                    return 'No';
                }
                else if (value == 2) {
                    return 'CBD';
                }
            },
            formatCategory(value) {
                if (value != null && typeof value['name'] != 'undefined') {
                    return value['name'];
                }
            },
            formatResponses(value) {
                let text1 = [];
                if (value.length > 0) {
                    value.forEach((values, index) => {
                        text1.push(values.name);
                    });
                    return text1.toString();
                } else return null;
            },
            searchFn(params) {
                this.updateParams({
                    searchTerm: params
                });
                this.loadItems();
                this.isLoading = false;
            },
            onChangeEventHandler(event) {
                if (event.value === true) {
                    // this.form.question = '';
                    // this.form.response = '';
                    // this.form.subQuestions = [];
                    this.form.subQuestions.push({
                        question: '',
                        answer: ''
                    })
                    this.showSubQuestions = true;
                } else {
                    // this.form.question = '';
                    // this.form.response = '';
                    // this.form.subQuestions = [];
                    this.form.subQuestions.push({
                        question: '',
                        answer: ''
                    })
                    this.showSubQuestions = false;
                }
            },
            onChangeAnswer(event) {
                if (event === 'yes') {
                    this.form.subQuestions = [];
                    this.form.subQuestions.push({
                        question: '',
                        answer: ''
                    })
                }
            },
            addQuestion() {
                this.form.subQuestions.push({
                    question: '',
                    answer: ''
                })
            },
            deletePpdSubQuestion(val) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        var index = this.form.subQuestions.indexOf(val);
                        if (this.form.id && val.id) {
                            let data = {
                                question_id: this.form.id,
                                sub_question_id: val.id,
                            }
                            API.deletePpdSubQuestion(
                                data,
                                data => {
                                    this.$snotify.success(data.message);
                                },
                                err => {})
                        }
                        if (index !== -1) {
                            this.form.subQuestions.splice(index, 1);
                        }
                    }
                })
            }
    
        },
        computed: {},
        mounted() {
            this.isLoading = true;
            this.loadItems();
            // this.BulkEdit();
            // this.getCategories();
            for (var input = 1; input <= 20; input++) {
                if (input == 1) {
                    this.form.order = 1;
                }
                this.orders.push(input);
            }
            // this.checkOrder(1);
        },
        // afterCreated() {
        // }
    }
</script>

<style>
    @media (max-width: 1150px) {
        .card .new-card-body {
                padding: 1.25rem 1.437rem !important;
            }
    }
</style>